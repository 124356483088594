import { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getEnrollment, getEnrollments } from 'src/api';
import authTokens from 'src/auth/auth-tokens';
import EnrollmentContext, { EnrollmentState } from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { TokenHelper } from 'src/helpers/token-helper';
import { isImpersonatingAtom } from 'src/state';
import { Enrollment, UserEnrollment } from 'src/types/enrollment';
import QueryKeys from 'src/types/query-keys';

interface Props {
  children: React.ReactNode;
}

export default function EnrollmentProvider({ children }: Props) {
  const { user } = useContext(UserContext);

  const [enrollment, setEnrollment] = useState<Enrollment>();
  const [enrollments, setEnrollments] = useState<UserEnrollment>();
  const tokenHelper = new TokenHelper(authTokens.getAccessToken());
  const isAdmin = tokenHelper.isAdmin();
  const [isImpersonating] = useAtom(isImpersonatingAtom);
  // TODO: Move queries out of context and into hooks
  const enrollmentsQuery = useQuery<AxiosResponse<UserEnrollment>, AxiosError>(
    QueryKeys.GET_ENROLLMENTS,
    getEnrollments,
    {
      onSuccess: ({ data }) => {
        setEnrollments(data);

        if (!user?.currentEnrollmentId) setEnrollment(data.enrollments[0]);
      },
      onError: (error) => {
        console.error(`Error querying all enrollments`, error);
      },
      enabled: !isAdmin || (isAdmin && isImpersonating),
    }
  );

  const enrollmentQuery = useQuery<AxiosResponse<Enrollment>, AxiosError>(
    [QueryKeys.GET_ENROLLMENT, user?.currentEnrollmentId],
    () => {
      if (!user?.currentEnrollmentId) {
        throw new Error(`'currentEnrollmentId' is not defined in user store.`);
      }
      return getEnrollment(user.currentEnrollmentId);
    },
    {
      enabled:
        !!user?.currentEnrollmentId || !isAdmin || (isAdmin && isImpersonating),
      onSuccess: ({ data }) => {
        setEnrollment(data);
      },
      onError: (error) => {
        console.error(`Error querying single enrollment`, error);
      },
    }
  );

  const { refetch, status, error } = user?.currentEnrollmentId
    ? enrollmentQuery
    : enrollmentsQuery;

  useEffect(() => {
    if (user?.currentEnrollmentId) {
      enrollmentQuery.refetch();
    }
  }, [user?.currentEnrollmentId]);

  const ctx: EnrollmentState = {
    userEnrollment: enrollments,
    enrollment,
    queryStatus: status,
    queryError: error,
    refetch,
  };

  return (
    <EnrollmentContext.Provider value={ctx}>
      {children}
    </EnrollmentContext.Provider>
  );
}
function mutate(
  updatedData: {
    hasElectives: boolean;
    hasPathways: boolean;
    academicStatus: import('src/types/enrollment').AcademicStatus;
    accountStatus: import('src/types/enrollment').AccountStatus;
    pathway?: any;
    campusCode?: string | null | undefined;
    campusCurrency?: string | null | undefined;
    campusId: string;
    campusName: string;
    creditAttempted: number;
    creditEarned: number;
    creditRequired: number;
    creditScheduled: number;
    currentTerm?: number | null | undefined;
    degree: import('src/types/enrollment').Degree;
    eligibleToReEnroll: boolean;
    enrollmentDate?: string | null | undefined;
    enrollmentId: string;
    enrollmentNumber: string;
    expirationDate?: string | null | undefined;
    gpa: number;
    hasElectivePools?: boolean | null | undefined;
    lmsUserId?: number | null | undefined;
    learningEnabled: boolean;
    partnerAccountCode?: string | null | undefined;
    program?: import('src/types/enrollment').Program | null | undefined;
    selectedPathway: boolean | null;
    sisEnrollmentId: number;
    totalNumberTerms?: number | null | undefined;
    userId: string;
  }[],
  arg1: boolean
) {
  throw new Error('Function not implemented.');
}
