/* eslint-disable react/react-in-jsx-scope */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_HOST } from 'src/auth/auth-host';
import AuthTokens from 'src/auth/auth-tokens';
import LoadingComponent from 'src/components/loading-component';
import { TokenHelper } from 'src/helpers/token-helper';
import { ADMIN, AUTH_ERROR, AUTH_LOGOUT, DASHBOARD } from 'src/routes/routemap';
import { authErrorAtom } from 'src/state/auth';
import { useAtom } from 'jotai';
import { updateUserLoginDateTime } from 'src/api/user';
import FeEvents from 'src/events/fe-events';

const config = process.env;

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authError, setAuthError] = useAtom(authErrorAtom);

  useEffect(() => {
    // Error Handling
    const err = searchParams.get('error');
    const errDescription = searchParams.get('error_description');
    console.log(err);

    if (err && errDescription) {
      setAuthError({
        type: err,
        description: errDescription,
      });
    }

    if (err && !errDescription?.includes('AADB2C90091')) return navigate(AUTH_ERROR);

    const {
      REACT_APP_AUTH_TENANT,
      REACT_APP_AUTH_POLICY,
      REACT_APP_ADMIN_POLICY,
      REACT_APP_AUTH_CLIENT_ID,
      REACT_APP_SCOPE_OF_BACK_WEB_API,
    } = config;

    let b2cPolicy = REACT_APP_AUTH_POLICY;
    const code = searchParams.get('code');
    const responseState = JSON.parse(searchParams.get('state') as string);
    if (!code) {
      return navigate(AUTH_LOGOUT);
    }
    if (responseState.policyType == 'Admin') b2cPolicy = REACT_APP_ADMIN_POLICY;
    const endpoint = `https://${AUTH_HOST}/${REACT_APP_AUTH_TENANT}.onmicrosoft.com/${b2cPolicy}/oauth2/v2.0/token`;
    const params = `grant_type=authorization_code&client_id=${REACT_APP_AUTH_CLIENT_ID}&code=${code}&redirect_uri=${
      window.location.origin
    }/auth/callback&scope=openid%20${REACT_APP_SCOPE_OF_BACK_WEB_API}&code_verifier=${sessionStorage.getItem('lccv')}`;
    axios
      .post(endpoint + '?' + params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        AuthTokens.setAccessToken(res.data.access_token);
        AuthTokens.setRefreshToken(res.data.refresh_token);
        sessionStorage.setItem('refreshToken', res.data.refresh_token);
      })
      .then(() => {
        const tokenHelper = new TokenHelper(AuthTokens.getAccessToken());
        const isAdmin = tokenHelper.isAdmin();

        if (AuthTokens.getAccessToken()) {
          try {
            updateUserLoginDateTime();
          } catch (error) {
            console.error('Error updating user login datetime:', error);
          }
          FeEvents.trackLoginEvent({ eventName: 'Sign-On' })
          const redirectTarget = responseState?.targetUrl || DASHBOARD;
          navigate(isAdmin ? ADMIN : redirectTarget);
        }
      })
      .catch((err) => {
        console.error(err);
        // navigate(AUTH_LOGOUT);
      })
      .finally(async () => {
        sessionStorage.removeItem('lccv');
      });
  }, []);

  return <LoadingComponent label={'Loading'} />;
}
